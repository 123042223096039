/* Appointment.css */
.appointment-container {
    margin: 20px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.appointment-container h2 {
    color: #333;
    margin-bottom: 15px;
}

.appointment-container table {
    width: 100%;
    border-collapse: collapse;
}

.appointment-container th, .appointment-container td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #eee;
}

.appointment-container th {
    background-color: #f5f5f5;
}

.appointment-container tr:hover {
    background-color: #eef;
}

.appointment-container td {
    color: #555;
}

button, .btn-primary.mx-2 {
    padding: 10px;
    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    background-color: #2D9596;
    color: white;
}

button:hover {
    background-color: #2D9596;
}

.appointment-container th {
    background-color: #ffffff; 
    color: #000000;      
    border-bottom: 1px solid #eee;
    text-align: left;
    padding: 8px;
}

